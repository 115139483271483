<template>
  <main id="tg-main" class="tg-main tg-haslayout">
    <div class="tg-sectionspace tg-haslayout">
      <div class="container">
        <div class="row">
          <div id="tg-twocolumns" class="tg-twocolumns">
            <figure class="tg-newsdetailimg">
              <video
                :src="video.fileUrl"
                :poster="video.cover"
                controls
                style="background:#000000;width:1160px;height:618px;"
              />
            </figure>
            <div class="tg-newsdetail">
              <div class="tg-posttitle" style="font-size:28px;">{{ video.title }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7 col-lg-7">
            <div class="tg-description"> 简介: {{ video.desc }} </div>
            <div class="" style="display:flex; justify-content: space-between;width: 400px;">
              <div>类型：{{ (video.type || {}).name }} </div>
              <div>发布时间：{{ moment(video._createTime).format('YYYY-MM-DD') }} </div>
              <div>浏览次数：{{ video.readed }}次 </div>
            </div>
          </div>
          <div class="col-md-5 col-lg-5">
            <div
              class="buttons"
              style="margin-top:23px;display:flex;align-items:center;justify-content:flex-end;"
            >
              <el-button type="primary" size="small" @click="AddFavorite">
                <div style="display:flex; align-items: center;">
                  <img
                    src="/images/icons/riFill-heart-add-fill.svg"
                    style="width:24px;height:24px;"
                    @click="AddFavorite"
                  />
                  <span style="margin-left: 5px;font-size:16px;">收藏</span>
                </div>
              </el-button>
              <el-button type="primary" plain size="small" @click="AddFavorite">
                <div style="display:flex; align-items: center;">
                  <img
                    src="/images/icons/riFill-share-fill Copy.svg"
                    style="width:24px;height:24px;"
                    @click="Share"
                  />
                  <span style="margin-left: 5px;font-size:16px;">分享</span>
                </div>
              </el-button>
            </div>
          </div>
        </div>
        <section-space :section-name="'相关视频'">
          <card-list :data="videos" />
        </section-space>
      </div>
    </div>
  </main>
</template>

<script>
import { SingleQuery, CommonQuery } from '../api/commonquery'
import CardList from '../components/CardList.vue'
import SectionSpace from '../components/SectionSpace'
import moment from 'moment'

export default {
  components: {
    CardList,
    SectionSpace,
  },
  data() {
    return {
      video: {},
      videos: [],
    }
  },
  methods: {
    moment,
    /**
     * 获取视频详情
     */
    async getData(id) {
      let res = await SingleQuery({
        model: 'video',
        id,
      })
      this.video = res.data
    },

    /**
     * 获取相关视频
     * 参数待调整
     */
    async getVideos() {
      let res = await CommonQuery({
        model: 'video',
        query: {},
        limit: 5,
      })
      this.videos = res.data.map((video) => {
        return {
          ...video,
          to: {
            name: 'videoDetail',
            query: {
              id: video._id,
            },
          },
        }
      })
    },

    //收藏本站
    AddFavorite() {
      const title = this.book.title
      const url = window.location
      try {
        window.external.addFavorite(url, title)
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, '')
        } catch (e) {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加')
        }
      }
    },

    Share() {},
  },
  created() {
    let query = this.$route.query
    if (query.id) {
      this.getData(this.$route.query.id)
      this.getVideos()
    } else {
      console.log('无id')
    }
  },
}
</script>

<style scoped>
.tg-description {
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 2; */
  /* overflow: hidden; */
}
</style>

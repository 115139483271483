<template>
  <div class="tg-post" style="display:flex;">
    <div style="display:flex; flex-wrap: wrap;">
      <div v-for="(card, index) in cards.slice(0, limit)" :key="index" style="margin-left:15px;margin-top:10px;">
        <card :card="card" width="210px" height="118px" fit="cover" />
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue'
export default {
  components: { Card },
  name: 'CardList',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      cards: [],
    }
  },
  watch: {
    data() {
      this.cards = this.data
    },
  },
}
</script>

<style scoped>
.video-container {
  justify-content: flex-start;
  display: flex;
  font-display: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: space-around;
}
</style>
